<script setup lang="ts"></script>

<template>
	<div class="flex flex-col items-center relative">
		<div
			class="w-full border-b border-dashed border-brown h-px"
			:class="{
				'absolute top-1/2 left-0 right-0': !!$slots.default
			}"
		></div>
		<div class="text-xs text-gray px-4 bg-inherit z-10" v-if="!!$slots.default">
			<slot />
		</div>
	</div>
</template>
